import React from "react"
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from "react-bootstrap/Container"
import ValidateInput from "../../../components/UI/Input/ValidateInput";
import { authenticationService } from "../../../services/basis/AuthenticationService"
import swal from "../../../utils/swal";
import Accepts from "../../../components/Accepts/Accepts";
import { history } from "../../../App"
import { handleError } from "../../../utils/handleCatch";

function CoopSignupForm(props) {

    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        authenticationService.coopSignup(data)
            .then(response => {
                swal.success("Registrazione effettuata",
                    "A breve riceverai una email per completare il processo di registrazione.");
                history.push("/");
            }).catch((error) => {
                handleError(error)
                if (error.errorCode === 13) {
                    swal.error("Password non valida");
                } else {
                    swal.error(error.message);
                }
            })
    }

    // console.log(watch())
    // console.log(watch('errors'))

    return (
        <Translation>
            {t =>

                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />

                    <Container className="signup-wrapper">
                        <div className="signup-form">
                            <p className="signup-form-title">{t('sign_in')}</p>

                            <Row className="signup-row">
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="username"
                                        label="email"
                                        type="email"
                                        watch={watch("username")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Indirizzo email non valido"
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.username && errors.username.message}
                                    </div>
                                </Col>
                            </Row>

                            <Row className="signup-row">
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="password"
                                        label="password"
                                        type="password"
                                        watch={watch("password")}
                                        register={register({
                                            required: "Campo obbligatorio"
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.password && errors.password.message}
                                    </div>
                                </Col>

                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="passwordConfirm"
                                        label="passwordConfirm"
                                        type="password"
                                        watch={watch("passwordConfirm")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            validate: {
                                                passwordMatch: value => value === watch('password')
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.passwordConfirm && errors.passwordConfirm.message}
                                        {errors.passwordConfirm && errors.passwordConfirm.type === "passwordMatch" && (
                                            <span>Le password non coincidono</span>
                                        )}
                                    </div>
                                </Col>
                                <Row className="container-fluid">
                                    <Col md={12} className="form-group">
                                        <small id="passwordHelp" className="form-text text-muted">
                                            La password deve essere lunga almeno 8 caratteri e contenere
                                            almeno tre tra: lettere minuscole, maiuscole, numeri e simboli
                                    </small>
                                    </Col>
                                </Row>
                            </Row>

                            <p className="signup-form-title mt-3">{t('signup.privacy')}</p>

                            <Accepts errors={errors} register={register} user={props.user} />

                            <div className="mt-3 mb-3">
                                <button className="custom-button outline" type="submit">
                                    {t("save")}
                                </button>
                            </div>
                        </div>
                    </Container>

                </form>
            }
        </Translation >
    );
}

export default CoopSignupForm;