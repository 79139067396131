import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { ReactComponent as Right } from "../../../images/right.svg"
import { ReactComponent as Left } from "../../../images/left.svg"
import Placeholder from "../../../images/product-placeholder.png"
import './variants.styles.scss'
import { history } from '../../../App'
import ReactTooltip from 'react-tooltip'

const ProductVariants = ({ item }) => {
    const [variants, setVariants] = useState([]);
    const ref = useRef();

    useEffect(() => {
        if (item.productVariants && item.productVariants.length > 0) {
            let newArray = [item];
            item.productVariants.map(element => {
                newArray.push(element);
            })
            setVariants(newArray);
        }
    }, [item])

    const calculateSlides = () => {
        if (variants.length > 1){
            if (variants.length > 5){
                return 4;
            }
            if (variants.length < 5){
                return variants.length -1
            }
        }
        return 1
    }

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        nextArrow: <Right />,
        prevArrow: <Left />,
        speed: 500,
        slidesToShow: calculateSlides(),
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: variants.length > 3 ? 3 : 0,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1640,
                settings: {
                    slidesToShow: variants.length > 2 ? 2 : 0,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: variants.length > 1 ? 1 : 0,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 979,
                settings: {
                    slidesToShow: variants.length > 4 ? 4 : 0,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 699,
                settings: {
                    slidesToShow: variants.length > 3 ? 3 : 0,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: variants.length > 2 ? 2 : 0,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 390,
                settings: {
                    slidesToShow: variants.length > 1 ? 1 : 0,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
        ]
    };

    const goToDetails = (barcode) => {
        history.push({
            pathname: `/product/${barcode}`,
        })
        ref.current.slickGoTo(0, false)
    }

    if (variants.length > 0) {
        return (
            <div className='variants-slider'>
                <Slider {...settings} ref={ref}>
                    {variants && variants.map((v, i) => (
                        <div className={i === 0 ? 'variants selected' : 'variants'} onClick={() => { goToDetails(v.barcode) }} key={v.barcode} data-tip data-for={v?.description}>
                            <div className='variants-image'>
                                <img src={v?.image.startsWith("http") ? v?.image : v?.imageUrl ? v?.imageUrl : Placeholder} alt="" onError={(e) => { e.target.src = Placeholder }} />
                            </div>
                            <div className='variants-text'>
                                {v?.description}
                            </div>
                        </div>
                    ))}
                </Slider>
                {variants && variants.map((v, i) => (
                    <ReactTooltip id={v?.description} key={i} place="bottom" effect="solid" backgroundColor="#F5F5F5" textColor="black">
                        {v?.description}
                    </ReactTooltip>
                ))}

            </div>
        )
    } else {
        return null
    }
}

export default ProductVariants