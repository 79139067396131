import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Translation } from "react-i18next";
import { ecommerceCartsService } from "../../../services/ecommerce/EcommerceCartsService"
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import { handleError } from "../../../utils/handleCatch";

class Carts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carts: []
        };
    }

    componentDidMount() {
        ecommerceCartsService.openCarts()
            .then(response => {
                this.setState({
                    carts: response.data,
                })
            }).catch((error) => {
                handleError(error)
                console.log(error);
            })
    }

    renderTotal = (cart) => {
        if (cart) {
            if (cart.totalToPay) {
                return `\u20AC ${cart.totalToPay.toFixed(2)}`
            } else if (cart.netTotal) {
                return `\u20AC ${cart.netTotal.toFixed(2)}`;
            } else {
                return `\u20AC 0.00`;
            }
        }
    }

    render() {
        const { carts } = this.state;
        const { cart, review } = this.props;
        return (
            <Translation>
                {t =>
                    <>
                        <span className="cart-menu-title mb-4">{t("cart.lists")}</span>
                        <Row className="cart-heading mb-4">
                            <Col sm={3}>
                                <span>{t("order.number")}</span>
                            </Col>
                            <Col sm={4}>
                                <span>{t("store")}</span>
                            </Col>
                            <Col sm={2}>
                                <span>{t("cart.deliveryMode")}</span>
                            </Col>
                            <Col>
                                <span>{t("total")}</span>
                            </Col>
                        </Row>
                        {carts && carts.map(item => (
                            <Row key={item.id} onClick={() => { review(item.id) }}
                                className={`cart-lists ${item.id === cart.id ? "alert-success" : ""}`} >
                                <Col sm={3}>
                                    <span>{item.title}</span>
                                </Col>
                                <Col sm={4}>
                                    <span>{item.storeDescription}</span>
                                </Col>
                                <Col sm={2}>
                                    <span>{t(`cart.deliveryMode.${item.deliveryMode}`)}</span>
                                </Col>
                                <Col>
                                    <span>{this.renderTotal(item)}</span>
                                </Col>
                            </Row>
                        ))}
                    </>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart
    };
}

const mapDispatchToProps = dispatch => {
    return {
        review: (cartId) => dispatch(actionCreators.review(cartId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Carts);
