
import { handleResponse } from '../../../utils/handleResponse';
import { checkResponse } from '../../../utils/checkResponse';
import config from '../../../config/config';
import axios from "axios"
import { authHeader } from "../../../utils/authHeader";
import { handleError } from "../../../utils/handleCatch";

export const addressesService = {
    getAddresses, newAddress, deleteAddress, getBillingAddresses, newBillingAddress, deleteBillingAddress
};

function getAddresses() {
    return (
        axios.get(`${config.baseUrl}/cli/profiles/addresses`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)

    );
}

function newAddress(data) {
    let body;
    if (data.id) {
        body = {
            id: data.id,
            address: data.address,
            state: data.state,
            city: data.city,
            county: data.county,
            zip: data.zip,
            streetNumber: data.streetNumber,
            nation: "IT"
        }
    } else {
        body = {
            address: data.address,
            state: data.state,
            city: data.city,
            county: data.county,
            zip: data.zip,
            streetNumber: data.streetNumber,
            nation: "IT"
        }
    }

    return (
        axios.post(`${config.baseUrl}/cli/profiles/addresses`,
            body
            ,
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)

    );
}

function deleteAddress(addressId) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/addresses/delete`, {

            id: addressId,
        },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)

    );
}

function getBillingAddresses() {
    return (
        axios.get(`${config.baseUrl}/cli/profiles/billing/addresses`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)

    );
}

function newBillingAddress(data) {

    // private String customerCode;
    // private String subject;
    // private String name;

    let body;
    if (data.id) {
        body = {
            id: data.id,
            address: data.address,
            city: data.city,
            county: data.county,
            zip: data.zip,
            streetNumber: data.streetNumber,
            nation: "IT",
            vatCode: data.vatCode,
            ssnCode: data.ssnCode,
            companyName: data.companyName,
            phone: data.phone,
            fax: data.fax,
            pec: data.pec,
            recipientCode: data.recipientCode
        }
    } else {
        body = {
            address: data.address,
            city: data.city,
            county: data.county,
            zip: data.zip,
            streetNumber: data.streetNumber,
            nation: "IT",
            vatCode: data.vatCode,
            ssnCode: data.ssnCode,
            companyName: data.companyName,
            phone: data.phone,
            fax: data.fax,
            pec: data.pec,
            recipientCode: data.recipientCode
        }
    }

    return (
        axios.post(`${config.baseUrl}/cli/profiles/billing/addresses`,
            body,
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)

    );
}

function deleteBillingAddress(addressId) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/billing/addresses/delete`, {
            id: addressId,
        },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)

    );
}

