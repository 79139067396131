import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { ecommerceService } from '../../../services/ecommerce/EcommerceService'
import Tag from './Tag'
import './tags.styles.scss'
import { ReactComponent as Right } from "../../../images/right.svg"
import { ReactComponent as Left } from "../../../images/left.svg"
import { handleError } from '../../../utils/handleCatch'

const HomeTags = () => {
    const [tags, setTags] = useState([])

    useEffect(() => {
        ecommerceService.getTags()
            .then(response => {
                console.log(response);
                setTags(response)
            })
            .catch(error => {
                handleError(error)
                console.log(error);
            })
    }, [])

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        nextArrow: <Right />,
        prevArrow: <Left />,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1640,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false
                }
            },
            {
                breakpoint: 325,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className='tags-slider'>
            <Slider {...settings}>
                {tags && tags.map((item) => (
                    <Tag item={item} key={item.id} />
                ))}
            </Slider>
        </div>
    )
}

export default HomeTags