import React from "react";
import boardingForm from "./Service";
import { Translation } from 'react-i18next';
import { handleError } from "../../../utils/handleCatch";

export default class Boarding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serverUrl: "",
            storename: "",
            txntype: "",
            txndatetime: "",
            chargetotal: "",
            currency: "",
            hash: "",
            hosteddataid: "",
            hosteddataidtype: "",
            addInfo3: "",
            addInfo4: "",
            responseSuccessURL: "",
            responseFailURL: ""
        };
    }

    componentDidMount() {
        boardingForm()
            .then(response => {
                this.setState({
                    serverUrl: response.serverUrl,
                    storename: response.storename,
                    txntype: response.txntype,
                    txndatetime: response.txndatetime,
                    chargetotal: response.chargetotal,
                    currency: response.currency,
                    hash: response.hash,
                    hosteddataid: response.hosteddataid,
                    hosteddataidtype: response.hosteddataidtype,
                    addInfo3: response.addInfo3,
                    addInfo4: response.addInfo4,
                    responseSuccessURL: response.responseSuccessURL,
                    responseFailURL: response.responseFailURL,
                })
            }).catch((error) => {
                handleError(error)
                console.log(error);
            })
    }

    render() {
        return (
            <Translation>
                {t =>
                    <div className="payments-container w-100">
                        <div className="view-container">
                            <p className="title">
                                {t('payments.boarding')}
                            </p>
                            <p>
                                Questa procedura vi richiederà di inserire i dati della vostra carta di credito ed effettuerà una
                                autorizzazione di pagmento con importo di € 0,01. La procedura ha il solo scopo di verificare la validità
                                del metodo di pagamento. L'importo NON verrà addebitato sulla vostra carta.
                            </p>
                            <div className="text-center">

                                <form method="post" action="https://pf.bnlpositivity.it/service/" target="_blank" >

                                    <input type="hidden" name="txntype" value={this.state.txntype} />
                                    <input type="hidden" name="txndatetime" value={this.state.txndatetime} />
                                    <input type="hidden" name="hash" value={this.state.hash} />
                                    <input type="hidden" name="storename" value={this.state.storename} />
                                    <input type="hidden" name="chargetotal" value={this.state.chargetotal} />
                                    <input type="hidden" name="currency" value={this.state.currency} />

                                    <input type="hidden" name="hosteddataid" value={this.state.hosteddataid} />
                                    <input type="hidden" name="hosteddataidtype" value={this.state.hosteddataidtype} />

                                    <input type="hidden" name="addInfo3" value={this.state.addInfo3} />
                                    <input type="hidden" name="addInfo4" value={this.state.addInfo4} />

                                    <input type="hidden" name="timezone" value={this.state.timezone} />
                                    <input type="hidden" name="mode" value={this.state.mode} />
                                    <input type="hidden" name="language" value={this.state.language} />

                                    <input type="hidden" name="responseSuccessURL" value={this.state.responseSuccessURL} />
                                    <input type="hidden" name="responseFailURL" value={this.state.responseFailURL} />

                                    <button type="submit" class="btn btn-primary btn-lg btn-block outline">Procedi</button>
                                </form>

                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}
