import { logoutAction } from "../redux/store/actions/user"
import store from "../redux/store";
import { history } from "../App";
import toaster from "./toaster";
export function handleError(error) {
    if (error.status && error.status === 401) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        toaster.error('Sessione scaduta. La preghiamo di effettuare il login')
        localStorage.removeItem("user")
        history.push("/")
        store.reduxStore.dispatch(logoutAction());
    }
    return error;
}