import React from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import Row from "react-bootstrap/Row";
import Loader from 'react-loader-spinner'
import CardProduct from "../../components/Products/CardProduct/CardProduct";
import { Translation } from 'react-i18next';
import { ecommerceService } from "../../services/ecommerce/EcommerceService"
import { connect } from "react-redux"
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";
import CategoryTree from "../Categories/CategoryTree/CategoryTree";
import Filter from "../../components/UI/Filter/Filter";
import { handleError } from "../../utils/handleCatch";
class NewSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            products: [],
            page: 0,
            total: 0,
            totalPages: null,
            hasMore: true,
            onlyPromotions: false,
            onlyBranded: false,
            filter: "",
            tag: props.location?.state?.tag ? props.location?.state?.tag : ""
        }
        this.searchProducts = (input, page) => {
            const storeId = this.props.cart ? this.props.cart.storeId : ""
            let sort = "";
            let direction = "ASC"
            if (this.state.filter && this.state.filter !== ""){
                let split = this.state.filter.split("-")
                sort = split[0]
                direction = split[1]
            }
            ecommerceService.searchProducts(input, storeId, page, "", this.state.onlyPromotions, this.state.onlyBranded, this.state.tag, sort, direction)
                .then(response => {
                    this.setState({
                        isLoaded: true,
                        products: response.data,
                        totalPages: response.totalPages,
                        page: response.number,
                        hasMore: !response.last,
                        total: response.number > this.state.page ? this.state.total + response.totalElements : response.totalElements
                    })
                }).catch((error) => {
                    handleError(error)
                    console.log(error);
                })
        }
    }

    componentDidMount() {
        const searchInput = this.props.location.state.searchInput ? this.props.location.state.searchInput : "";
        this.searchProducts(searchInput, this.state.page)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newInput = nextProps.location.state.searchInput ? nextProps.location.state.searchInput : "";
        if (newInput !== this.props.location.state.searchInput) {
            this.searchProducts(newInput, 0)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.onlyPromotions !== prevState.onlyPromotions ||
            this.state.onlyBranded !== prevState.onlyBranded || this.state.filter !== prevState.filter || this.state.tag !== prevState.tag) {
            const searchInput = this.props.location.state.searchInput ? this.props.location.state.searchInput : "";
            this.searchProducts(searchInput, this.state.page)
        }
    }

    onChange = (e) =>{ 
        this.setState({ [e.target.name]: e.target.name !== "tag" && e.target.name !== "filter" ? e.target.checked : e.target.value });
    }

    deleteFilter = () => {
        this.setState({
            onlyPromotions: false,
            onlyBranded: false,
            filter: "",
            tag: ""
        })
    }

    loadMore = () => {
        const page = this.state.page + 1;
        if (page < this.state.totalPages) {
            const storeId = this.props.cart ? this.props.cart.storeId : ""
            let sort = "";
            let direction = "ASC"
            if (this.state.filter){
                let split = this.state.filter.split("-")
                sort = split[0]
                direction = split[1]
            }
            ecommerceService.searchProducts(this.props.location.state.searchInput, storeId, page, "", this.state.onlyPromotions, this.state.onlyBranded, this.state.tag, sort, direction)
                .then(response => {
                    const newResponse = this.state.products;
                    newResponse.push(...response.data);
                    this.setState({
                        products: newResponse,
                        totalPages: response.totalPages,
                        page: response.number,
                        hasMore: !response.last,
                        total: response.number > this.state.page ? this.state.total + response.totalElements : response.totalElements
                    })
                }).catch((error) => {
                    handleError(error)
                    console.log(error);
                })
        }
    }

    render() {
        const { products, hasMore, isLoaded } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="d-flex page-grid">
                        <div className="grid-left" offsettop={0} offsetbottom={0}>
                            <CategoryTree />
                        </div>
                        <div className="grid-right">
                            <div className="mobile-view-search" style={{ minHeight: "300px" }}>
                                <div className="container-fluid search-product-view">
                                    <div className="row h-100">
                                        <div className="container-fluid max-product-row">
                                            <InfiniteScroll
                                                style={{ overflowX: "hidden" }}
                                                dataLength={products.length}
                                                next={this.loadMore}
                                                hasMore={hasMore}
                                                loader={<Loader
                                                    type="ThreeDots"
                                                    color="#333"
                                                    height={70}
                                                    width={70}
                                                    timeout={3000} //3 secs
                                                />}
                                            >
                                                <Filter
                                                    onChange={this.onChange}
                                                    total={this.state.total}
                                                    onlyBranded={this.state.onlyBranded}
                                                    onlyPromotions={this.state.onlyPromotions}
                                                    tag={this.state.tag}
                                                    filter={this.state.filter}
                                                    deleteFilter={this.deleteFilter}
                                                />
                                                <Row className="d-flex justify-content-center">
                                                    {products && products.length > 0 && products.map((item) => (
                                                        <CardProduct key={item.id} item={item} onClick={this.props.onClick} />
                                                    ))}
                                                    {isLoaded && products && products.length === 0 &&
                                                        <span className="title">Nessun prodotto trovato</span>
                                                    }
                                                </Row>
                                            </InfiniteScroll>
                                            <ScrollToTopButton />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart
    };
}

export default connect(mapStateToProps)(NewSearch);
