import React from "react";
import Row from "react-bootstrap/Row"
// import PaymentsLogo from "../../../../images/payment_circuits.png"
import payments from "./Service"
import { paymentsService } from "../../../Payments/Service"
import i18n from "../../../../i18n"
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";
import { history } from "../../../../App";
import { handleError } from "../../../../utils/handleCatch";
class PaymentsMode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentsMode: [],
            isBoarded: false,
            selectedPayment: ''
        };
    }

    freezeCart = (paymentMode) => {
        const { freeze, cart } = this.props;
        freeze(cart.id, paymentMode);
    }

    componentDidMount() {
        const deliveryMode = this.props.cart.deliveryMode;
        const storeId = this.props.cart.storeId;
        payments(deliveryMode, storeId)
            .then(response => {
                this.setState({
                    paymentsMode: response.data,
                })
            }).catch((error) => {
                handleError(error)
                console.log(error);
            })

        paymentsService.paymenthMethods()
            .then(response => {
                console.log(response);
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        console.log(method);
                        if (method.paymentMethodType === "XPAY") {
                            this.setState({ isBoarded: true })
                        }
                    });
                }
            }).catch((error) => {
                handleError(error)
                console.log(error);
            })
    }

    renderButtons = (paymentMode) => {
        const { setPaymentMode } = this.props;
        const { selectedPayment } = this.state;
        switch (paymentMode) {
            case "CASH":
                return (
                    <>
                        {/* Only on large device */}
                        <button className={selectedPayment === paymentMode ? "outline d-none d-lg-block button-active" : "outline d-none d-lg-block"} onClick={() => { setPaymentMode(paymentMode); this.setState({ selectedPayment: paymentMode }) }}>
                            <div className="to-hide" style={selectedPayment === paymentMode ? { display: 'none' } : {}}>
                                <span className="primary-text" style={{ fontSize: "12px" }}>{i18n.t("paymentMode.cash.title")}</span>
                                <br></br>
                                <span>{i18n.t("paymentMode.cash.subTitle")}</span>
                            </div>
                            <div className={selectedPayment === paymentMode ? "selected-payment-text" : "hover-text"} >{i18n.t("paymentMode.delivery.CASH")}</div>
                        </button>
                        {/* Only on small device */}
                        <button className={selectedPayment === paymentMode ? "outline d-lg-none mobile-active" : "outline d-lg-none mobile"} onClick={() => { setPaymentMode(paymentMode); this.setState({ selectedPayment: paymentMode }) }}>
                            <div>{i18n.t("paymentMode.delivery.CASH")}</div>
                        </button>
                    </>
                );
            case "BANCOMAT":
                return (
                    <>
                        {/* Only on large device */}
                        <button className={selectedPayment === paymentMode ? "outline d-none d-lg-block button-active" : "outline d-none d-lg-block"} onClick={() => { setPaymentMode(paymentMode); this.setState({ selectedPayment: paymentMode }) }}>
                            <div className="to-hide" style={selectedPayment === paymentMode ? { display: 'none' } : {}}>
                                <span className="primary-text" style={{ fontSize: "12px" }}>{i18n.t("paymentMode.cash.title")}</span>
                                <br></br>
                                <span>{i18n.t("paymentMode.bancomat.subTitle")}</span>
                            </div>
                            <div className={selectedPayment === paymentMode ? "selected-payment-text" : "hover-text"}>{i18n.t("paymentMode.delivery.BANCOMAT")}</div>
                        </button>
                        {/* Only on small device */}
                        <button className={selectedPayment === paymentMode ? "outline d-lg-none mobile-active" : "outline d-lg-none mobile"} onClick={() => { setPaymentMode(paymentMode); this.setState({ selectedPayment: paymentMode }) }}>
                            <div>{i18n.t("paymentMode.delivery.BANCOMAT")}</div>
                        </button>
                    </>
                );
            case "XPAY":
                if (this.state.isBoarded) {
                    return (
                        <>
                            {/* Only on large device */}
                            <button className={selectedPayment === paymentMode ? "outline d-none d-lg-block button-active" : "outline d-none d-lg-block"} onClick={() => { setPaymentMode(paymentMode); this.setState({ selectedPayment: paymentMode }) }}>
                                <div className="to-hide" style={selectedPayment === paymentMode ? { display: 'none' } : {}}>
                                    <span className="primary-text" style={{ fontSize: "12px" }}>
                                        {i18n.t("paymentMode.online.title")}</span>
                                </div>
                                <div className={selectedPayment === paymentMode ? "selected-payment-text" : "hover-text"}>{i18n.t("paymentMode.BANCOMAT")}</div>
                            </button>
                            {/* Only on small device */}
                            <button className={selectedPayment === paymentMode ? "outline d-lg-none mobile-active" : "outline d-lg-none mobile"} onClick={() => { setPaymentMode(paymentMode); this.setState({ selectedPayment: paymentMode }) }}>
                                <div>{i18n.t("paymentMode.BANCOMAT")}</div>
                            </button>
                        </>
                    );
                } else {
                    return (
                        <>
                            {/* Only on large device */}
                            <button className={selectedPayment === paymentMode ? "outline d-none d-lg-block button-active" : "outline d-none d-lg-block"} onClick={() => {history.push("/profile/payments") }}>
                                <div className="to-hide" style={selectedPayment === paymentMode ? { display: 'none' } : {}}>
                                    <span className="primary-text" style={{ fontSize: "12px" }}>
                                        {i18n.t("paymentMode.online.add")}</span>
                                </div>
                                <div className={selectedPayment === paymentMode ? "selected-payment-text" : "hover-text"}>{i18n.t("paymentMode.online.add")}</div>
                            </button>
                            {/* Only on small device */}
                            <button className={selectedPayment === paymentMode ? "outline d-lg-none mobile-active" : "outline d-lg-none mobile"} onClick={() => {history.push("/profile/payments") }}>
                                <div>{i18n.t("paymentMode.online.add")}</div>
                            </button>
                        </>
                    )
                }
            default: return null;
        }
    }

    render() {
        const { paymentsMode } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="w-100">
                        <div style={{ marginBottom: "20px" }} className="signup-form-title" >
                            {t("checkout.paymentsMode.title")}
                        </div>
                        <div className="container-fluid">
                            {paymentsMode && paymentsMode.map(paymentMode => (
                                <Row key={paymentMode}>
                                    {this.renderButtons(paymentMode)}
                                </Row>
                            ))}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default PaymentsMode;



