import React from "react";

import Modal from "../../UI/Modal/Modal"
import Login from "../../../features/Login/Login"
import Search from "../../../features/Search/Search"
import Categories from "../../../features/Categories/Categories";
import Availability from "../../../features/Availability/Availability";

import NewHeader from "./NewHeader/NewHeader";
import MobileHeader from "./MobileHeader/MobileHeader"

import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService";
import { handleError } from "../../../utils/handleCatch";

class ResponsiveHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: false,
            showAvailability: false,
            showSearch: false,
            showCategories: false,

            searchInput: ""
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.key !== prevProps.location.key
            && this.props.location.pathname !== "/search") {
            this.setState({ searchInput: "" })
        }
    }

    onChange = (e) => this.setState({ searchInput: e.target.value });

    closeAll = () => this.setState({
        showLogin: false,
        showAvailability: false,
        showSearch: false,
        showCategories: false,
    })

    loginModal = () => this.setState({
        showLogin: !this.state.showLogin,
        showAvailability: false,
        showSearch: false,
        showCategories: false,
    })

    availabilityModal = () => this.setState({
        showAvailability: !this.state.showAvailability,
        showLogin: false,
        showSearch: false,
        showCategories: false,
    })

    searchModal = () => this.setState({
        showSearch: !this.state.showSearch,
        showLogin: false,
        showAvailability: false,
        showCategories: false,
    })

    categoriesModal = () => this.setState({
        showCategories: !this.state.showCategories,
        showLogin: false,
        showAvailability: false,
        showSearch: false,
    })

    renderTotal = (cart) => {
        if (cart) {
            if (cart.netTotal) {
                return `${cart.netTotal.toFixed(2)} \u20AC`;
            } else {
                return `0.00 \u20AC`;
            }
        } else {
            return `0.00 \u20AC`;
        }
    }

    render() {
        const { user } = this.props;
        return (
            <>
                <div className="d-none d-lg-block">
                    <NewHeader
                        {...this.props}
                        closeAll={this.closeAll}
                        loginModal={this.loginModal}
                        availabilityModal={this.availabilityModal}
                        categoriesModal={this.categoriesModal}
                        searchModal={this.searchModal}
                        renderTotal={this.renderTotal}

                        showCategories={this.state.showCategories}
                        showLogin={this.state.showLogin}

                        searchInput={this.state.searchInput}
                        onChange={this.onChange}
                        availability={this.props.availability}
                    />
                </div>

                <div className="d-lg-none">
                    <MobileHeader
                        {...this.props}
                        closeAll={this.closeAll}
                        loginModal={this.loginModal}
                        availabilityModal={this.availabilityModal}
                        categoriesModal={this.categoriesModal}
                        searchModal={this.searchModal}
                        renderTotal={this.renderTotal}

                        showCategories={this.state.showCategories}
                        showLogin={this.state.showLogin}

                        searchInput={this.state.searchInput}
                        onChange={this.onChange}
                    />
                </div>

                {
                    this.state.showSearch &&
                    <Modal show={this.state.showSearch} close={this.searchModal}>
                        <Search showSearch={this.state.showSearch} onClick={this.searchModal} />
                    </Modal>
                }
                {
                    this.state.showLogin &&
                    <Modal show={this.state.showLogin} close={this.loginModal}>
                        <Login user={user} closeAll={this.closeAll} />
                    </Modal>
                }
                {
                    this.state.showAvailability &&
                    <Modal show={this.state.showAvailability} close={this.availabilityModal}>
                        <Availability />
                    </Modal>
                }
                {
                    this.state.showCategories &&
                    <Modal show={this.state.showCategories} close={this.categoriesModal}>
                        <Categories closeAll={this.closeAll} />
                    </Modal>
                }
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        availability: state.cart.availability,
    };
}

export default withRouter(connect(mapStateToProps)(ResponsiveHeader));
