import React, { useEffect, useState } from "react";
import timeslotsService from "./Service";
import { Col } from 'react-bootstrap'
import { handleError } from "../../../../utils/handleCatch";

const Timeslots = ({ day, selectTimeslot, cartId }) => {
  const [timeslots, setTimeslots] = useState([])

  useEffect(() => {
    timeslotsService(cartId, day)
      .then(response => {
        setTimeslots(response.data)
      }).catch((error) => {
        handleError(error)
        console.log(error);
      })
  }, [day])


  return (
    <div className="disponibility-ranges justify-content-center row">
      {timeslots && timeslots.map((item) => (
        <Col key={item.id} xs={6} sm={3} md={3} className="mt-3 px-0 text-center disponibility-ranges-item">
          <button id={item.id}
            className={item.available ? `pill outline` : `pill-disabled pill outline`}
            onClick={() => selectTimeslot(item.id)}
          >
            {item.validFrom} - {item.validTo}
          </button>
        </Col>
      ))}
    </div>
  );
}

export default Timeslots