import * as actionTypes from "./actionTypes"
import toaster from "../../../utils/toaster"
import { ecommerceCartsService } from "../../../services/ecommerce/EcommerceCartsService"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import { paymentsService } from "../../../features/ProfileContainer/Payment/Service"
import { history } from "../../../App"
import { handleError } from "../../../utils/handleCatch"

export const cartAction = (cart) => {
    return {
        type: actionTypes.CART,
        cart: cart
    }
}

export const availabilityAction = (data) => {
    return {
        type: actionTypes.AVAILABILITY,
        availability: data
    }
}

export const saveCheckoutInfoAction = (data) => {
    return {
        type: actionTypes.CHECKOUT_DAY_INFO,
        data
    }
}

export const saveCheckoutInfo = (data) => {
    return (dispatch) => {
        console.log('saveCheckoutInfo', data)
        dispatch(saveCheckoutInfoAction(data))
    }
}

export const current = () => {
    return (dispatch, getState) => {
        ecommerceCartsService.current()
            .then(response => {
                dispatch(cartAction(response.data))
                localStorage.setItem('cartId', response.data.id);
                localStorage.setItem('deliveryMode', response.data.deliveryMode);
                localStorage.setItem('storeId', response.data.storeId);
                localStorage.setItem('storeDescription', response.data.storeDescription);
                ecommerceService.availability()
                    .then(response => {
                        dispatch(availabilityAction(response.data))
                    }).catch((error) => {
                        handleError(error)
                        console.log(error);
                    })

                if (getState().cart.productToAdd) {
                    const item = getState().cart.productToAdd;
                    ecommerceCartsService.store(getState().cart.id, item.barcode, item.amount, item.um)
                        .then((response) => {
                            const um = response.data.updatedProduct.um;
                            dispatch(addProductToAddAction(response.data.cart))
                            toaster.standard(`Hai ${item.amount} ${um} ${response.data.updatedProduct.description} nel carrello`)
                        }).catch((error) => {
                            handleError(error)
                            console.log(error);
                            toaster.error(error.message);
                        })
                }
            }).catch((error) => {
                handleError(error)
                console.log(error);
                dispatch(cartAction(null))
            })
    }
};

export const availability = () => {
    return dispatch => {
        ecommerceService.availability()
            .then(response => {
                dispatch(availabilityAction(response.data))
            }).catch((error) => {
                handleError(error)
                console.log(error);
            })
    }
};

export const open = (deliveryMode, storeId, addressId) => {
    return (dispatch, getState) => {
        ecommerceCartsService.open(deliveryMode, storeId, addressId)
            .then((response) => {
                dispatch(cartAction(response.data.cart))
                // add unauthenticathed product
                if (getState().cart.productToAdd) {
                    const item = getState().cart.productToAdd;
                    ecommerceCartsService.store(response.data.cart.id, item.barcode, item.amount, item.um)
                        .then((response) => {
                            const um = response.data.updatedProduct.um;
                            dispatch(addProductToAddAction(response.data.cart))
                            toaster.standard(`Hai ${item.amount} ${um} ${response.data.updatedProduct.description} nel carrello`)
                        }).catch((error) => {
                            handleError(error)
                            console.log(error);
                            toaster.error(error.message);
                        })
                }
                // get first availability
                ecommerceService.availability()
                    .then(response => {
                        dispatch(availabilityAction(response.data))
                    }).catch((error) => {
                        handleError(error)
                        console.log(error);
                    })
            }).catch((error) => {
                handleError(error)
                toaster.error(error.message);
            })
    }
};

export const store = (cartId, barcode, amount, um) => {
    return dispatch => {
        ecommerceCartsService.store(cartId, barcode, amount, um)
            .then((response) => {
                const um = response.data.updatedProduct.um;
                dispatch(cartAction(response.data.cart))
                toaster.standard(`Hai ${amount} ${um} ${response.data.updatedProduct.description} nel carrello`)
            }).catch((error) => {
                handleError(error)
                console.log(error);
                toaster.error(error.message);
            })
    }
};

export const remove = (cartId, barcode) => {
    return dispatch => {
        ecommerceCartsService.remove(cartId, barcode)
            .then((response) => {
                dispatch(cartAction(response.data.cart))
                toaster.standard(`${response.data.updatedProduct.description} rimosso dal carrello`)
            }).catch((error) => {
                handleError(error)
                console.log(error);
            })
    }
};

export const freeze = (data, cartId, paymentMode, setState) => {
    return dispatch => {
        ecommerceCartsService.freeze(data, cartId, paymentMode)
            .then(response => {
                if (response?.data?.cart?.paymentMode === "XPAY") {
                    const orderCode = response.data.cart.code;
                    paymentsService.nonce(orderCode)
                        .then(response => {
                            paymentsService.threeDS(response)
                                .then(response => {
                                    console.log(response);
                                    if (response.esito && response.esito === "OK") {
                                        setState({ htmlString: response.html, showModal: true });
                                    } else {
                                        history.push({
                                            pathname: `/checkout/failed`,
                                            state: {
                                                orders: response.data,
                                            }
                                        })
                                    }
                                })
                                .catch(error => {
                                    handleError(error)
                                    console.log(error);
                                })
                        })
                        .catch(error => {
                            handleError(error)
                            console.log(error);
                        })
                } else {
                    history.push("/checkout/confirm");
                    dispatch(cartAction(null))
                    localStorage.removeItem('cartId');
                    localStorage.removeItem('storeId');
                    localStorage.removeItem('deliveryMode');
                    localStorage.removeItem('storeDescription');

                    ecommerceCartsService.current()
                        .then(response => {
                            dispatch(cartAction(response.data))
                            localStorage.setItem('cartId', response.data.id);
                            localStorage.setItem('deliveryMode', response.data.deliveryMode);
                            localStorage.setItem('storeId', response.data.storeId);
                            localStorage.setItem('storeDescription', response.data.storeDescription);
                            ecommerceService.availability()
                                .then(response => {
                                    dispatch(availabilityAction(response.data))
                                }).catch((error) => {
                                    console.log(error);
                                })
                        }).catch((error) => {
                            handleError(error)
                            console.log(error);
                        })
                }

            }).catch((error) => {
                handleError(error)
                toaster.error(error.message)
                console.log(error);
            })
    }
};


export const move = (cartId, storeId) => {
    return dispatch => {
        ecommerceCartsService.move(cartId, storeId)
            .then(response => {
                history.push("/cart");
                dispatch(cartAction(response.data.cart))
            }).catch((error) => {
                handleError(error)
                console.log(error);
            })
    }
};

export const abort = (cartId) => {
    return dispatch => {
        ecommerceCartsService.abort(cartId)
            .then((response) => {
                dispatch(cartAction(null))
                dispatch(availabilityAction(null))
                localStorage.removeItem('cartId');
                localStorage.removeItem('storeId');
                localStorage.removeItem('deliveryMode');
                localStorage.removeItem('storeDescription');

                ecommerceCartsService.current()
                    .then(response => {
                        dispatch(cartAction(response.data))
                        localStorage.setItem('cartId', response.data.id);
                        localStorage.setItem('deliveryMode', response.data.deliveryMode);
                        localStorage.setItem('storeId', response.data.storeId);
                        localStorage.setItem('storeDescription', response.data.storeDescription);
                        ecommerceService.availability()
                            .then(response => {
                                dispatch(availabilityAction(response.data))
                            }).catch((error) => {
                                handleError(error)
                                console.log(error);
                            })
                    }).catch((error) => {
                        handleError(error)
                        console.log(error);
                    })

            }).catch((error) => {
                handleError(error)
                console.log(error);
            })
    }
};

export const review = (cartId) => {
    return dispatch => {
        ecommerceCartsService.review(cartId)
            .then(response => {
                dispatch(cartAction(response.data.cart))
                localStorage.setItem('cartId', response.data.cart.id);
                localStorage.setItem('deliveryMode', response.data.cart.deliveryMode);
                localStorage.setItem('storeId', response.data.cart.storeId);
                localStorage.setItem('storeDescription', response.data.cart.storeDescription);
            }).catch((error) => {
                handleError(error)
                toaster.error("Carrello non valido");
            })
    }
};

export const setProductToAddAction = (product) => {
    return {
        type: actionTypes.SET_PRODUCT_TO_ADD,
        productToAdd: product
    }
}

export const setProductToAdd = (product, amount) => {
    return dispatch => {
        product.amount = amount;
        dispatch(setProductToAddAction(product))
    }
};

export const addProductToAddAction = (cart) => {
    return {
        type: actionTypes.ADD_PRODUCT_TO_ADD,
        cart: cart,
        productToAdd: null
    }
}

export const copy = (orderId) => {
    return dispatch => {
        ecommerceCartsService.copyOrder(orderId)
            .then(response => {
                console.log(response);
                if (response && response.responseCode === 4105) {
                    dispatch(freezeCheckAction(response.data, true))
                    history.push("/cart");
                    localStorage.setItem('cartId', response.data.cart.id);
                } else if (response && response.responseCode === 0) {
                    dispatch(cartAction(response.data.cart))
                    history.push("/cart");
                    localStorage.setItem('cartId', response.data.cart.id);
                }
            })
    }
};

export const freezeCheck = (data, showPopup) => {
    return dispatch => {
        dispatch(freezeCheckAction(data, showPopup))
    }
};

export const freezeCheckAction = (data, showPopup) => {
    return {
        type: actionTypes.FREEZE_CHECK,
        cart: data.cart,
        updatedItems: data.updatedItems,
        removedItems: data.removedItems,
        showFreezePopup: showPopup
    }
}

export const closeFreezePopup = () => {
    return dispatch => {
        dispatch(closeFreezePopupAction())
    }
};

export const closeFreezePopupAction = () => {
    return {
        type: actionTypes.CLOSE_FREEZE_POPUP,
        updatedItems: null,
        removedItems: null,
        showFreezePopup: false
    }
}

export const categories = () => {
    return dispatch => {
        ecommerceService.categories()
            .then(response => {
                console.log(response)
                dispatch(categoriesAction(response.data));
            }).catch((error) => {
                handleError(error)
                console.log(error);
            })
    }
};

export const categoriesAction = (categories) => {
    return {
        type: actionTypes.CATEGORIES,
        categories: categories
    }
}
