import React, { useState, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel'
import {bannersService} from "./Service"
import { ReactComponent as Right } from "../../../images/right.svg"
import { ReactComponent as Left } from "../../../images/left.svg"
import { useMediaQuery } from "react-responsive";
import { history } from "../../../App";
import { handleError } from "../../../utils/handleCatch";

const HomeCarousel = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [banners, setBanners] = useState([]);
    const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

    const settings = {
        prevIcon: <div className="backgroundIndicator"><Left /></div>,
        nextIcon: <div className="backgroundIndicator"><Right /></div>
    }

    useEffect(() => {
        bannersService.banners().then(response => {
            setIsLoaded(true);
            setBanners(response.data)
        })
            .catch(error => {
                handleError(error)
                console.log(error);
            });
    }, [])

    const goToDetails = (item) => {
        if (item.hasProducts) {
            history.push({
                pathname: `/banners/${item.id}/products`,
                state: {
                    bannerId: `${item.id}`,
                }
            })
        }
    }


    return (
        isLoaded ?
            <Carousel className="Carousel" {...settings}>
                {banners.map((item, index) => (
                    <Carousel.Item key={item.id} style={{ height: "480px" }}>
                        <div className="link" onClick={() => goToDetails(item)}>
                            <img src={!isMobile ? item.imageUrl : item.imageApp} alt="" />
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>

            : null
    );

}

export default HomeCarousel;