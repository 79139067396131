import React, { useEffect, useState, useCallback } from "react"
import CategoryTree from "../Categories/CategoryTree/CategoryTree";
import CardProduct from "../../components/Products/CardProduct/CardProduct";
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loader-spinner'
import { ecommerceService } from "../../services/ecommerce/EcommerceService";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux"
import * as actionCreators from "../../redux/store/actions"
import Filter from "../../components/UI/Filter/Filter";
import "./search-category.styles.scss";
import ProductBreadcrumb from "../../components/UI/Breadcrumb/ProductBreadcrumb";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";
import { handleError } from "../../utils/handleCatch";

const NewSearchCategory = ({
    match,
    cart,
    defaultStore,
    categoryId,
    changeCategory,
    location
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const storeId = cart ? cart.storeId : (defaultStore ? defaultStore.id : 1109);
    const categories = useSelector(state => state.categories);
    const allCategories = useSelector(state => state.cart.categories);
    const [loadedProducts, setLoadedProducts] = useState(categories.loadedProducts ? categories.loadedProducts : null);
    const [savedScroll, setSavedScroll] = useState(location.state ? location.state.savedScroll : 0);
    const [page, setPage] = useState(categories.page ? categories.page : 0);
    const [totalPages, setTotalPages] = useState(categories.totalPages ? categories.totalPages : null);
    const [products, setProducts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [total, setTotal] = useState(0);
    const [state, setState] = useState({
        onlyBranded: false,
        onlyPromotions: false,
        tag: "",
        filter: ""
    });
    const first = match.params.firstLevelCategory;
    const second = match.params.secondLevelCategory;
    const third = match.params.thirdLevelCategory;


    const getProducts = useCallback(
        (page) => {
            let sort = "";
            let direction = "ASC"
            if (state.filter && state.filter !== "") {
                let split = state.filter.split("-")
                sort = split[0]
                direction = split[1]
            }
            const newCategory = categoryId === "" || categoryId === undefined ? getCategoryId() : categoryId;
            ecommerceService.searchProducts("", storeId, page, newCategory, state.onlyPromotions, state.onlyBranded, state.tag, sort, direction)
                .then(response => {
                    console.log(response.data)
                    setProducts(response.data)
                    setTotalPages(response.totalPages)
                    setHasMore(!response.last)
                    setTotal(response.totalElements)
                    setPage(response.number);
                }).catch((error) => {
                    handleError(error)
                    console.log(error);
                })
        }, [categoryId, match.params.categoryId, state.onlyBranded, state.onlyPromotions, storeId, state.tag, state.filter]
    );

    const getCategoryId = () => {

        let result;
        if (first) {
            result = allCategories ? allCategories.find(categorie => first === categorie.description) : null;
            if (second) {
                result = result?.items.find(categorie => second === categorie.description);
                if (third) {
                    result = result?.items.find(categorie => third === categorie.description);
                }
            }
        }
        return result ? result?.id : null;
    }

    useEffect(() => {
        if (categoryId === "") {
            changeCategory(match.params.categoryId)
            dispatch(actionCreators.resetScrolledProduct());
        }
    })

    useEffect(() => {
        if (!loadedProducts) {
            dispatch(actionCreators.resetScrolledProduct());
            getProducts(0);
            window.scrollTo(0, 0)
        } else {
            setLoadedProducts(null);
            setProducts(categories.loadedProducts.map(item => { return item }));// per poter aggiungere gli altri prodotti caricati dopo
            window.scrollTo(0, categories.scroll)
        }
    }, [categoryId, state.onlyPromotions, state.onlyBranded, state.tag, state.filter, getProducts]) // eslint-disable-line

    const loadMore = () => {
        const newPage = page + 1;
        if (newPage < totalPages) {
            let sort = "";
            let direction = "ASC"
            if (state.filter && state.filter !== "") {
                let split = state.filter.split("-")
                sort = split[0]
                direction = split[1]
            }
            const newCategory = categoryId === "" || categoryId === undefined ? getCategoryId() : categoryId;
            ecommerceService.searchProducts("", storeId, newPage, newCategory, state.onlyPromotions, state.onlyBranded, state.tag, sort, direction)
                .then(response => {
                    const newResponse = products;
                    newResponse.push(...response.data);
                    setProducts(newResponse)
                    setTotalPages(response.totalPages)
                    setHasMore(!response.last)
                    setTotal(response.totalElements)
                    setPage(response.number);
                }).catch(error => {
                    handleError(error)
                    console.log(error);
                })
        }
    }

    const onChange = (e) => {
        setState({...state, [e.target.name]: e.target.name !== "tag" && e.target.name !== "filter" ? e.target.checked : e.target.value});
    }

    useEffect(() => {
        console.log(state);
    }, [state])

    const deleteFilter = () => {
        setState({
            onlyPromotions: false,
            onlyBranded: false,
            filter: "",
            tag: ""
        })
    }

    return (
        <div className="search-category">
            <div className="page-grid mobile-view-search">
                <div className="grid-left" offsettop={0} offsetbottom={0}>
                    <CategoryTree />
                </div>
                <div className="grid-right">
                    <div className="category-container">
                        <div className="category-breadcrumb-container">
                            {products && products.length > 0 &&
                                <ProductBreadcrumb second={second} third={third} />
                            }
                        </div>
                    </div>

                    <InfiniteScroll
                        style={{ overflowX: "hidden" }}
                        dataLength={products.length}
                        next={loadMore}
                        hasMore={hasMore}
                        onScroll={() => setSavedScroll(window.scrollY)}
                        loader=
                        {<Loader
                            type="ThreeDots"
                            color="#333"
                            height={70}
                            width={70}
                            timeout={3000}
                        />}
                    >
                        <Filter
                            onChange={onChange}
                            total={total}
                            onlyBranded={state.onlyBranded}
                            onlyPromotions={state.onlyPromotions}
                            tag={state.tag}
                            filter={state.filter}
                            deleteFilter={deleteFilter}
                        />
                        {products && products.length > 0 &&
                            <div className="products-grid">
                                {products.map((item, index) => (
                                    <CardProduct
                                        key={item.id}
                                        item={item}
                                        activeKey={categories.categoryId}
                                        loadedProducts={products}
                                        savedScroll={savedScroll}
                                        from={location.pathname}
                                        page={page}
                                        totalPages={totalPages}
                                    />
                                ))}
                            </div>
                        }
                        {products && products.length === 0 &&
                            <div className="d-flex justify-content-center">
                                <span className="title">{t("searchCategory.noItemsPresent")}</span>
                            </div>
                        }
                    </InfiniteScroll>
                    <ScrollToTopButton />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        defaultStore: state.user.defaultStore,
        categoryId: state.categories.categoryId,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        changeCategory: (categoryId, index) => dispatch(actionCreators.changeCategory(categoryId, index)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSearchCategory);