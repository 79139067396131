import CustomError from "./customError";
import store from "../redux/store";
import { logoutAction } from "../redux/store/actions/user"
import { history } from "../App";

export function handleResponse(response) {
    if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        localStorage.removeItem("user")
        // if (store.reduxStore.getState().user.defaultStore) {
        //     history.push("/home")
        // } else {
        //     history.push("/")
        // }
        history.push("/")
        store.reduxStore.dispatch(logoutAction());
        throw new CustomError(response.status, "Errore di comunicazione col server");
    }

    if ([400].indexOf(response.status) !== -1) {
        return Promise.reject("Errore di comunicazione col server");
    }

    // const contentType = response.headers.get("content-type");
    // if (contentType && contentType.indexOf("application/json") !== -1) {
    //     return response.json();
    // } else {
    // }
    return response.data;
}

