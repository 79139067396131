import React, { useState, useEffect, useCallback } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loader-spinner'
import { useTranslation } from 'react-i18next';
import { bannersService } from "./Service";
import CardProduct from "../../../components/Products/CardProduct/CardProduct";
import CategoryTree from "../../Categories/CategoryTree/CategoryTree";
import ScrollToTopButton from "../../../components/ScrollToTopButton/ScrollToTopButton";
import "./bannerProductList.styles.scss"
import { useSelector } from "react-redux";
import { handleError } from "../../../utils/handleCatch";

const BannerProductList = ({ location, match }) => {
    const { t } = useTranslation();
    const [isLoaded, setIsLoaded] = useState(false);
    const cart = useSelector(state => state.cart.cart)
    const storeId = cart && cart.storeId ? cart.storeId : 1109;
    const [page, setPage] = useState(0);
    const size = 10;
    const [totalPages, setTotalPages] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [banners, setBanners] = useState();
    const [bannersWithProducts, setBannersWithProducts] = useState([]);
    const [bannerProducts, setBannerProducts] = useState();
    const [bannerProductsApp, setBannerProductsApp] = useState();

    const bannerId = location?.state?.bannerId ? location?.state?.bannerId : match.params.bannerId ? match.params.bannerId : "";

    const bannersProducts = useCallback(
        (input, page, size, storeId) => {
            bannersService.bannersProductList(input, page, size, storeId).then(response => {
                setBannersWithProducts(response.data);
                setTotalPages(response.totalPages);
                setPage(response.number);
            }).catch((error) => {
                handleError(error)
                console.log(error)
            })
        },
        [],
    );

    const bannersHeader = useCallback(
        (input, page) => {
            bannersService.bannersHeader(input).then(response => {
                console.log(response)
                setBanners(response.data)
            }).catch((error) => {
                handleError(error)
                console.log(error)
            })
        },
        [],
    );


    useEffect(() => {
        bannersProducts(bannerId, 0, size, storeId);
        bannersHeader(bannerId)
    }, [bannersProducts, bannersHeader, bannerId])

    const loadMore = () => {
        if (totalPages !== undefined) {
            const newPage = page + 1;
            if (newPage < totalPages) {
                bannersService.bannersProductList(bannerId, newPage, size).then(response => {
                    const newResponse = bannersWithProducts;
                    newResponse.push(...response.data);
                    setTotalPages(response.totalPages);
                    setPage(response.number);
                    setBannersWithProducts(newResponse)
                }).catch((error) => {
                    handleError(error)
                    console.log(error)
                })
            } else {
                setHasMore(false);
            }
        }
    }

    return (
        <div className="search-results">
            <div className="page-grid mobile-view-search">
                <div className="grid-left" offsettop={0} offsetbottom={0}>
                    <CategoryTree />
                </div>
                <div className="grid-right">
                        <img className="bannerProduct desktop" src={banners?.imageUrl} alt="" />
                        <img className="bannerProduct mobile" src={banners?.imageApp} alt="" />
                    <InfiniteScroll
                        style={{ overflowX: "hidden" }}
                        dataLength={bannersWithProducts.length}
                        next={loadMore}
                        hasMore={hasMore}
                        loader={<Loader
                            type="ThreeDots"
                            color="#333"
                            height={70}
                            width={70}
                            timeout={3000}
                        />}
                    >
                        <div className="products-grid mobile-products-search" style={{marginTop: '30px'}}>
                            {bannersWithProducts && bannersWithProducts.length > 0 && bannersWithProducts.map((item) => (
                                <CardProduct key={item.id} item={item} />
                            ))}
                            {isLoaded && bannersWithProducts && bannersWithProducts.length === 0 &&
                                <span className="title">
                                    {t("newSearch.noProductsFound")}
                                </span>
                            }
                        </div>
                    </InfiniteScroll>
                    <ScrollToTopButton />
                </div>
            </div>
        </div>
    );
}

export default BannerProductList;



