import React from "react";
import { Translation } from 'react-i18next';
import { withRouter } from "react-router-dom"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService";
import { dayNumber } from "../../../utils/dateUtils";
import { handleError } from "../../../utils/handleCatch";

import "../Confirm/confirm.styles.scss"

class Failed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: null
        }
    }

    componentDidMount() {
        ecommerceService.orders(0)
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        order: response.data[0],
                    })
                }
            }).catch((error) => {
                handleError(error)
                console.log(error);
            })
        // setTimeout(() => {
        //     this.props.history.push("");
        // }, 3000);
    }

    render() {
        const { order } = this.state;
        console.log(order);
        return (
            <Translation>
                {t =>
                    <div className="checkout-confirm">
                        <div className="checkout-confirm-title text-center">
                            Errore!<br />Il pagamento non è andato a buon fine. Vi chiediamo di riprovare.
                        </div>
                        <div className="checkout-confirm-resume">
                            <div className="checkout-confirm-resume-title">
                                Numero ordine: <span>{order && order.code}</span>
                            </div>
                            <div className="row">
                                <div className="col-4 label">Totale prodotti:</div>
                                <div className="col-8 text">
                                    {order && order.cart && order.cart.itemsCount}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 label">Importo:</div>
                                <div className="col-8 text">
                                    {order && order.cart && `${order.cart.totalToPay.toFixed(2)} \u20AC `}
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button style={{ maxWidth: "280px" }}
                                className="custom-button outline"
                                onClick={() => {
                                    this.props.history.push("/profile/orders");
                                }}>
                                Vai ai miei oridni
                            </button>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default withRouter(Failed);

